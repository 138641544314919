import { Col, Divider, Row, Skeleton, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ImageUpload } from "../../components/input/imageUpload";
import { IImageFile } from "../../../model/images";
import { globleStyles } from "../../../GlobalStyles";
import { useAppDispatch, useAppSelector } from "../../../model/store/hooks";
import { fetchImageList } from "../../../controller/images";
import message from "antd/lib/message";
import { IWorkOrderInfo } from "../../../model/workorders";
import moment from "moment";
import { DATE_FORMATE_FOR_UI } from "../../../model/constants/constant";
import Card from "antd/lib/card";

const WorkOrderImages: React.FC = () => {
  const { workOrderId } = useParams<{ workOrderId: string }>();

  const [technicianImageList, setTechnicianImageList] = useState<IImageFile[]>(
    []
  );
  const [trackingSnapShotImageList, setTrackingSnapShotImageList] = useState<
    IImageFile[]
  >([]);

  const { imageListData, imageListLoading, imageListError } = useAppSelector(
    (state) => state.image
  );

  const dispatch = useAppDispatch();

  const [workOrderInfo, setWorkOrderInfo] = useState<IWorkOrderInfo>();

  //Call use effect to read workOrderInfo param from url and set it to state
  useEffect(() => {
    //Read workOrderInfo param from url
    const urlParams = new URLSearchParams(window.location.search);
    const workOrderInfoParam = urlParams.get("workOrderInfo");
    if (workOrderInfoParam) {
      const workOrderInfo = JSON.parse(atob(workOrderInfoParam));
      setWorkOrderInfo(workOrderInfo);
    }
  }, []);

  // API calls for the work order details page
  useEffect(() => {
    if (workOrderId)
      dispatch(fetchImageList({ id: workOrderId, type: "workorders" }));
  }, [dispatch, workOrderId]);

  useEffect(() => {
    if (imageListData.data.length > 0) {
      const fileListToUpdate: any[] = [];
      //sorty imagelistData by Created date to show in chronological order
      const sortedimageList = Array.from(imageListData.data);
      sortedimageList.sort((a, b) => {
        return a.originalFileName && b.originalFileName
          ? a.originalFileName.localeCompare(b.originalFileName)
          : 0;
      });

      sortedimageList.forEach((element) => {
        const initialImageInfo = {
          uid: element.id,
          name: element.originalFileName,
          url: element.imageUrl,
          thumbUrl: element.imageThumbnailBase64
            ? `data:image/png;base64, ${element.imageThumbnailBase64}`
            : element.imageUrl,
          catagory: element.type,
          status: "removed",
          tableName: element.tableName,
        };
        fileListToUpdate.push(initialImageInfo);
      });
      setTrackingSnapShotImageList(
        fileListToUpdate.filter(
          (element) => element.catagory === "TRACKING_SNAPSHOT"
        )
      );
      setTechnicianImageList(
        fileListToUpdate.filter((element) => element.catagory === "TECHNICIAN")
      );
    } else {
      setTechnicianImageList([]);
      setTrackingSnapShotImageList([]);
    }
  }, [imageListData.data]);

  //in case of error show error message
  useEffect(() => {
    if (imageListError) message.error(imageListError);
  }, [imageListError]);

  return (
    <>
      {/* //in case of no images show message  */}
      {/* //in case of error show error message */}
      {/* {imageListError && <Typography>{imageListError}</Typography>} */}
      {!imageListLoading &&
        technicianImageList.length === 0 &&
        trackingSnapShotImageList.length === 0 && (
          <Typography style={globleStyles.cardBody}>
            No images available for this work order. Kindly check with your
            service provider.
          </Typography>
        )}
      <h2>Work Order Images</h2>
      {/* <Typography style={globleStyles.cardBody}>Work Order Images</Typography> */}
      <Divider />
      {/* //If work order info is available show it */}
      {workOrderInfo && (
        <>
          <Row justify={"space-between"}>
            <Col span={24}>
              {/* <Typography style={globleStyles.inputHeaderText}>
                Work Order Info
              </Typography> */}
              {/* #6982  not to reveal the confidential information*/}
              {/* <Typography style={globleStyles.cardBody}>
                <b>Serivce Provider:</b> {workOrderInfo.subscriberName}
              </Typography> */}
              <Typography style={globleStyles.cardBody}>
                <b>Work Order#:</b> {workOrderInfo.name}
              </Typography>
              <Typography style={globleStyles.cardBody}>
                <b>Service Type:</b> {workOrderInfo.serviceType}
              </Typography>
              <Typography style={globleStyles.cardBody}>
                <b>Service Date:</b>{" "}
                {moment(workOrderInfo.serviceDate).format(DATE_FORMATE_FOR_UI)}
              </Typography>
              <Typography style={globleStyles.cardBody}>
                <b>Property Address:</b> {workOrderInfo.propertyAddress}
              </Typography>
              {/* #6982 not to reveal the confidential information  */}
              {/* <Typography style={globleStyles.cardBody}>
                <b>Administrator Contact:</b> {workOrderInfo.administratorName}
              </Typography> */}
            </Col>
          </Row>
          <Divider />
        </>
      )}

      <Row justify={"space-between"}>
        <Col span={24}>
          <Typography style={globleStyles.inputHeaderText}>
            Technician's Images
          </Typography>
          {/* //Show message if no images are uploaded */}
          {!imageListLoading && technicianImageList.length === 0 && (
            <Typography style={globleStyles.cardBody}>
              No images available
            </Typography>
          )}
          <div>
            {imageListLoading && <Skeleton />}
            <ImageUpload
              fileList={technicianImageList}
              handleChange={(info) => {}}
            />
          </div>
        </Col>
        <Divider />
        {!imageListLoading && trackingSnapShotImageList.length > 0 && (
          <Col span={24}>
            <Typography style={globleStyles.inputHeaderText}>
              Tracking Snapshots
            </Typography>

            <div>
              {imageListLoading && <Skeleton />}
              <ImageUpload
                fileList={trackingSnapShotImageList}
                handleChange={(info) => {}}
              />
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};

export default WorkOrderImages;
